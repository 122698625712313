import React, { memo, useContext } from 'react'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { internationalRoutes, routeNames } from '../../utils/helpers/routes'
import { iconLock } from '../../utils/icons'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { PrismicBreakpointPicture } from '../PrismicPicture'

const ListFeaturedProjects = ({ className = '', projects, light }) => {
  const { language } = useContext(I18nextContext)
  const localizedRouteProject =
    internationalRoutes[routeNames.projects].langs[language]
  const classLinkColor = light ? 'link--black' : 'link--white'
  return (
    <div className={`list--featured-projects ${className}`}>
      {projects.length > 0 &&
        projects.map((project, index) => {
          const {
            uid,
            cover_image,
            cover_image_all,
            cover_image_md,
            name,
            client,
            description,
            has_page,
            has_nda,
          } = project
          return (
            <div className="project-element row f--desktop" key={index}>
              <div className="col col-lg-7 mb-3 mb-lg-0">
                <PrismicBreakpointPicture
                  className="img--full img--top-left"
                  fallbackImg={cover_image}
                  sources={{ all: cover_image_all, md: cover_image_md }}
                  fallbackAlt={name.text}
                />
              </div>
              <div className="col col-lg-offset-1 col-lg-4">
                <h3 className="h5 ft-400 mb-1">
                  {client.document.data.name.text}
                </h3>
                <h2
                  className={`ft-default-m-primary ft-400 c-alpha-5 mb-3 mb-md-4 ${
                    light ? 'c-black' : 'c-white'
                  }`}
                >
                  {name.text}
                </h2>
                <p className="ft-default-m-primary mb-2 mb-md-3">
                  {description.text}
                </p>
                {(has_page && !has_nda) ? (
                  <LocalizedTransitionLink
                    className={`link link--arrow ${classLinkColor}`}
                    entryTransitionType="slideIn"
                    exitTransitionType="none"
                    to={`${localizedRouteProject}/${uid}`}
                  >
                    <span className="ft-truncat">{name.text}</span>
                  </LocalizedTransitionLink>
                ) : (
                  <div className={`link link--disabled ${classLinkColor}`}>
                    <span className="mr-2">{name.text}</span>
                    {iconLock}
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default memo(ListFeaturedProjects)
