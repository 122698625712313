import gsap from "gsap"
import { animateModalEntry, gsapParams, setModalEntry } from "./common"

const selectors = {
  wrapper: '.tpl-industry',
  page: {
    bottom: '.tpl-industry .page__bottom',
  },
  featuredList: {
    wrapper: '.list--featured-projects',
    items: '.list--featured-projects .project-element',
    title: '.list--featured-projects h3',
    subtitle: '.list--featured-projects h2',
    text: '.list--featured-projects p',
    link: '.list--featured-projects .link'
  },
  list: {
    wrapper: '.list--project',
    items: '.list--project li'
  }
}

const setFeaturedProjectListEntry = (show) => {
  gsap.set(selectors.featuredList.items, show ? gsapParams.showInUp : gsapParams.fadeOutDown60 )
  gsap.set([selectors.featuredList.title, selectors.featuredList.subtitle, selectors.featuredList.text], show ? gsapParams.showInUp : gsapParams.fadeOutDown50 )
  gsap.set(selectors.featuredList.link, show ? gsapParams.showInUp : gsapParams.fadeOutDown20 )
}

const setProjectListEntry = (show) => {
  const listItems = document.querySelectorAll(selectors.list.items)

  if (!listItems || !listItems.length) {
    return
  }
  gsap.set(selectors.list.items, show ? gsapParams.show : gsapParams.fadeOut )
}

const animateProjectListEntry = () => {
  const listItems = document.querySelectorAll(selectors.list.items)

  if (!listItems || !listItems.length) {
    return
  }

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.list.wrapper,
      start: "top 90%",
      end: "bottom center",
      once: true
    }
  })
  tl.to(selectors.list.items, { ...gsapParams.fadeIn, stagger: 0.05 }, 0)

  return tl
}


const animateFeaturedProjectListEntry = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.featuredList.wrapper,
      start: "top 90%",
      end: "bottom center",
      once: true
    }
  })

  tl.to(selectors.featuredList.items, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0)
  tl.to(selectors.featuredList.title, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0.15)
  tl.to(selectors.featuredList.subtitle, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0.2)
  tl.to(selectors.featuredList.text, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0.25)
  tl.to(selectors.featuredList.link, { ...gsapParams.fadeInUp, stagger: 0.05 }, 0.3)

  return tl
}

const setEntry = () => {
  setModalEntry({ onlyFadeInDesc: true})
  setFeaturedProjectListEntry()
  setProjectListEntry()
}

const setVisibleEntry = () => {
  setModalEntry({ onlyFadeInDesc: true}, true)
  setFeaturedProjectListEntry(true)
  setProjectListEntry(true)
}

const animateEntry = () => {
  const tl = []
  tl.push(animateModalEntry(selectors.wrapper, { onlyFadeInDesc: true }))
  tl.push(animateFeaturedProjectListEntry(selectors.page.bottom))
  tl.push(animateProjectListEntry())

  return tl
}

export {
  animateEntry,
  setVisibleEntry,
  setEntry
}